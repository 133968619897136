import { Button, FormControl, FormControlLabel, Grid, Typography } from "@mui/material";
import { Field, Formik } from "formik";
import { Switch, TextField } from "formik-mui";
import { forwardRef, lazy, useCallback, useEffect, useState } from 'react';
import { Form } from 'react-router-dom';
import * as Yup from 'yup';
import Avatar from "~/components/Avatar";
import FacilityAutocomplete from "~/components/Dropdowns/FacilityAutocomplete";
import UserRolesDropdown from "~/components/Dropdowns/UserRolesDropdown";
import WorkProviderDropdown from "~/components/Dropdowns/WorkProviderDropdown";
import { mergeObjectLeft } from "~/helpers/dataHelpers";
import { copyToClipboard } from "~/helpers/generalHelper";
import loadable from "~/helpers/loadable";
import { generateRandomPassword } from "~/helpers/stringHelpers";
import { checkUsername } from "~/requests/users";


const PasswordStrengthBar = loadable(lazy(() => import('react-password-strength-bar')));

// whitelist of fields and default values
const formTemplate = {
	name: '',
	email: '',
	mobile: '',
	pin: '',
	ibs_username: '',
	is_external: false,
	facilities: [],
	roles: [],
	workprovider_uuid: null,
	password: '',
	password_confirmation: '',
};

const UserValidationSchema = ({ changePassword, passwordScore, usernameExists }) => Yup.object().shape({
	name: Yup.string().required('Required').min(3, 'Name is too Short').max(100, 'Name is too long'),
	email: Yup.string().required('Required').email("Invalid email").test('username-exists', 'Username already exists', () => !usernameExists),
	pin: Yup.string().nullable().matches(/^\d+$/, 'Pin must be a number').min(4, 'Pin must contain 4-6 digits').max(6, 'Pin must contain 4-6 digits'),
	mobile: Yup.string().nullable().matches(/^04\d{8}$/, 'The phone number must be a valid Australian mobile number starting with 04 and be 10 digits long.'),
	is_external: Yup.boolean(),
	workprovider_uuid: Yup.string().nullable(),
	password: changePassword
		? Yup.string().required('Required').test('password-score', 'Password is too weak', () => passwordScore >= 2)
		: Yup.string(),
	password_confirmation: changePassword
		? Yup.string().required('Required').oneOf([Yup.ref('password')], 'Passwords must match')
		: Yup.string(),
});

const UserForm = forwardRef(({ entity = null, isNew = false, isMyProfile = false, onSubmit }, ref) => {
	const [changePassword, setChangePassword] = useState(isNew);
	const [usernameExists, setUsernameExists] = useState(false);
	const [passwordScore, setPasswordScore] = useState(0);

	const handleSubmit = (values, formikBag) => {
		// send values and inject other values if applicable
		const formValues = {
			...values,
			roles: values.roles.map(item => item.name),
			facilities: values.facilities.map(item => item.uuid)
		};

		if (!changePassword) {
			delete formValues.password;
			delete formValues.password_confirmation;
		}
		onSubmit(formValues, formikBag);
	};

	const checkUsernameUnique = useCallback(async (formik, username) => {
		let exists = false;
		if (username) {
			formik.setFieldTouched('email', true);
			const response = await checkUsername({ username: username, ignore_uuid: entity?.uuid });
			exists = !!response.data?.data?.match;
			formik.setFieldError('email', exists ? 'Username already exists' : undefined);
		}
		setUsernameExists(exists);
		return exists;
	}, [entity?.uuid]);

	// this fixes race condition so formik shows the error
	useEffect(() => {
		if (ref.current) ref.current.validateField('password');
	}, [ref.current, passwordScore]);

	return (
		<Formik
			initialValues={mergeObjectLeft(formTemplate, entity)}
			onSubmit={handleSubmit}
			validationSchema={() => UserValidationSchema({ changePassword, passwordScore, usernameExists })}
			innerRef={ref}
		>
			{formik => (
				<Form>
					<Grid container>
						<Grid item padding={2} xs={12} sm={6} display="flex" flexDirection="column" gap={2}>

							<Typography variant="h3" component="h3" gutterBottom>
								Profile Details
							</Typography>

							<Field
								component={TextField}
								name="name"
								label="Full Name"
								fullWidth
								required
							/>

							<Field
								component={TextField}
								name="email"
								label="Email / Username"
								id="email"
								autoComplete="username"
								inputProps={{ 'aria-label': 'Email Address', 'spellCheck': 'false', autoCapitalize: 'none' }}
								fullWidth
								required
								onBlur={(e) => checkUsernameUnique(formik, e.target.value)}
							/>

							<Field
								component={TextField}
								name="mobile"
								label="Mobile"
								fullWidth
							/>

							<Field
								component={TextField}
								name="pin"
								label="Pin"
								fullWidth
							/>

							{!isMyProfile && (
								<>
									<FormControlLabel
										control={
											<Field
												label="External User"
												name="is_external"
												type="checkbox"
												component={Switch}
											/>
										}
										label="External User"
										labelPlacement='end'
									/>

									<Typography variant="h3" component="h3" gutterBottom>
										Permissions
									</Typography>

									<Field name="roles" required>
										{({ field }) => (
											<UserRolesDropdown value={field.value} onChange={formik.setFieldValue} />
										)}
									</Field>

									<Field name="facilities">
										{({ field }) => (
											<FormControl fullWidth>
												<FacilityAutocomplete value={field.value} onChange={formik.setFieldValue} />
											</FormControl>
										)}
									</Field>

									<Field name="workprovider_uuid">
										{({ field, meta }) => (
											<WorkProviderDropdown
												{...field}
												initalValue={entity.workprovider?.id}
												fullWidth
												formikMeta={meta}
												onChange={formik.setFieldValue}
											/>
										)}
									</Field>
								</>
							)}

							<Typography variant="h3" component="h3" gutterBottom>
								Password
							</Typography>

							{changePassword && (
								<>
									{!isNew && (

										<Button variant="text" color="primary" onClick={() => setChangePassword(false)}>
											Cancel Password Change
										</Button>
									)}
									<PasswordStrengthBar
										password={formik.values.password}
										scoreWordStyle={{ textTransform: 'capitalize' }}
										onChangeScore={setPasswordScore}
									/>
									<Field
										component={TextField}
										name="password"
										label="Password"
										autoComplete="new-password"
										id="new-password"
										type="password"
										inputProps={{ 'aria-label': 'Password', 'spellCheck': 'false', autoCapitalize: 'none', dir: 'ltr' }}
										fullWidth
										required
									/>
									<Field
										component={TextField}
										name="password_confirmation"
										label="Confirm Password"
										type="password"
										fullWidth
										required
									/>
									<Button variant="outlined" color="primary" onClick={() => {
										const randomPassword = generateRandomPassword();
										formik.setFieldValue('password', randomPassword);
										formik.setFieldValue('password_confirmation', randomPassword);
										formik.setFieldTouched('password', true);
										formik.setFieldTouched('password_confirmation', true);
										copyToClipboard(randomPassword);

									}}> Set Random Password</Button>
								</>
							)}
							{!changePassword && (
								<Button variant="text" color="primary" onClick={() => setChangePassword(true)}>
									Change Password
								</Button>
							)}

							<Typography variant="h3" component="h3" gutterBottom>
								Other
							</Typography>

							<Field
								component={TextField}
								name="ibs_username"
								label="iBodyshop Username"
								fullWidth
							/>
						</Grid>
						<Grid item padding={2} xs={12} sm={6} display="flex" flexDirection="column" alignItems="center">

							<Avatar name={isNew ? "" : entity.name} size={120} />
							{/* <Button variant="text" color="primary" sx={{ mt: 2, width: 150 }}>
								Upload Image
							</Button> */}

						</Grid>
					</Grid>

					<FormikContextHelper />
				</Form>
			)}
		</Formik>
	);
});

const FormikContextHelper = () => {
	// console.log('errors', formik.errors);
	return null;
}

UserForm.displayName = 'UserForm';

export default UserForm;