import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DateTime } from 'luxon';
import { memo, useMemo } from 'react';
import { getInsurerColour } from '~/helpers/generalHelper';
import Avatar from './Avatar';
import FacilityChip from './FacilityChip';
import FormStatusButton from './FormStatusButton';
import SubmissionSummaryAudit from './SubmissionSummaryAudit';
import TileButton from './TileButton';

const SubmissionSummaryTile = memo(({
	submission,
	showAuthor = false,
	showStatus = true,
	showAudit = false,
	audit = null,
	onClick = null
}) => {
	const theme = useTheme();
	const isXs = useMediaQuery(theme.breakpoints.down("sm"));
	const isMd = useMediaQuery(theme.breakpoints.down("md"));
	const workProvider = submission.workprovider?.name;

	const bgcolor = useMemo(() => getInsurerColour(submission.workprovider?.name), [submission]);
	return (
		<TileButton
			sx={{
				padding: 1,
				paddingLeft: workProvider ? 3 : 1,
				borderRadius: 1,
				position: 'relative',
			}}
			onClick={onClick}
		>
			{workProvider && (
				<Box sx={{
					position: 'absolute',
					top: 0,
					left: 0,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					height: '100%',
					fontSize: 14,
					fontWeight: 500,
					lineHeight: 1,
					width: 20,
					backgroundColor: bgcolor,
				}}>
					{[...(workProvider)].slice(0, isMd ? 6 : 4).map(char => (<span style={{ color: 'white' }} key={char}>{char}</span>))}
				</Box>
			)}

			<Grid container sx={{ px: 0.5 }} spacing={1}>
				<Grid item xs={8} sm={9} md={showStatus || showAudit ? 6 : 10} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
					<Box sx={{ marginRight: 1 }}>
						{submission.summary?.customer && (
							<>
								<Box display='flex' alignItems='center' flexWrap='wrap' >
									<Typography sx={{ mr: 0.5, fontWeight: 500 }}>{submission.summary?.customer}</Typography>
									<FacilityChip facility={submission.assessment?.facility} />
								</Box>
								<Box>
									{submission.summary?.vehicle} [{submission.summary?.rego}]
								</Box>
							</>
						)}
						{!submission.summary?.customer && (
							<Typography>
								Pending Job Details
							</Typography>
						)}
					</Box>
				</Grid>

				<Grid item xs={4} sm={3} md={2} display={'flex'} alignItems={isXs ? 'flex-start' : 'center'} justifyContent={isXs ? 'flex-end' : 'flex-start'} >
					{!isXs && showAuthor && (
						<Avatar name={submission?.created_by?.name} size={38} sx={{ marginRight: 1 }} />
					)}
					<Box textAlign={isXs ? 'right' : 'left'} sx={{ overflow: 'hidden' }}>
						<Typography fontWeight={600} fontSize={isXs ? 13 : undefined} className='text-truncate'>
							{submission.form?.name}
						</Typography>
						<Typography fontSize={12} className='text-truncate'>
							{DateTime.fromISO(submission.updated_at).toRelative()}
						</Typography>
						{isXs && showAuthor && (
							<Avatar name={submission?.created_by?.name} inline size={24} />
						)}
					</Box>
				</Grid>

				{showStatus && (
					<Grid item xs={12} sm={12} md={4}
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<FormStatusButton status={submission.status} sx={{ p: isXs ? 0 : 1 }} />
					</Grid>
				)}

				{(showAudit && audit) && (
					<Grid item xs={12} sm={12} md={4}
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<SubmissionSummaryAudit audit={audit} />
					</Grid>
				)}
			</Grid>
		</TileButton>
	);
});
SubmissionSummaryTile.displayName = 'SubmissionSummaryTile';

export default SubmissionSummaryTile;