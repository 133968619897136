import { Card, ListItemButton } from '@mui/material'
import { memo } from 'react'

const TileButton = memo(({ children, sx = {}, onClick }) => {
	return (
		<Card variant='outlined'>
			<ListItemButton onClick={onClick} sx={{
				display: 'block', '&.Mui-disabled': {
					opacity: 1,
				}, ...sx
			}} disabled={!onClick}>
				{children}
			</ListItemButton>
		</Card>
	)
});

TileButton.displayName = 'TileButton'


export default TileButton