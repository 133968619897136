import { ExpandMore } from '@mui/icons-material';
import { Button, CircularProgress, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ClearIcon } from '@mui/x-date-pickers-pro';
import { useMemo, useState } from 'react';

const TextDropdown = ({
	name,
	label = null,
	options,
	dataKey,
	dataText,
	selected = [], // array for multiselect or dataKey value for single select
	multiSelect = false,
	hideClearButton = false,
	placeholder = 'Choose an option',
	limit = Infinity,
	loading = false,
	sx = {},
	onChange,
	...props
}) => {
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleSelect = (option) => {
		let updatedOptions;

		if (multiSelect) {
			// Return the array of selected objects
			const isSelected = selected.includes(option);
			if (isSelected) {
				updatedOptions = selected.filter((item) => item !== option);
			} else if (selected.length < limit) {
				updatedOptions = [...selected, option];
			} else {
				return; // Prevent selecting more than the limit
			}
		} else {
			// Return only the key value
			updatedOptions = option[dataKey];
		}

		onChange(name, updatedOptions);
	};

	const displayText = useMemo(() => {
		if (multiSelect) {
			return selected.length > 0
				? `${selected[0][dataText]}${selected.length > 1 ? ` +${selected.length - 1}` : ''}`
				: placeholder;
		} else {
			const foundOption = selected ? options.find(option => option[dataKey] === selected) : null;
			return foundOption ? foundOption[dataText] : placeholder;
		}
	}, [selected, options, dataKey, dataText, placeholder, multiSelect]);

	const selectedKeys = useMemo(() => (
		multiSelect
			? selected.length > 0 ? selected.map((item) => item[dataKey]) : []
			: selected ? [selected] : []
	), [selected]);

	return (
		<>
			<Button
				variant="text"
				color="inherit"
				disabled={loading}
				sx={{
					textTransform: 'none',
					alignItems: 'center',
					justifyContent: 'space-between',
					textAlign: 'left',
					...sx
				}}
				onClick={handleClick}
				{...props}
			>
				<Box mr={0.5}>
					{label && (
						<Box fontSize="75%">{label}</Box>
					)}
					<Box color={selectedKeys.length > 0 ? "body1" : "text.disabled"}>
						{displayText}
					</Box>
				</Box>

				<Box display="flex" alignItems="center" gap={0.5}>
					{(selectedKeys.length > 0 && !hideClearButton) && (
						<IconButton size="small" sx={{ p: 0.5 }} onClick={(e) => {
							e.stopPropagation();
							onChange(name, multiSelect ? [] : null);
						}}>
							<ClearIcon sx={{ fontSize: '80%' }} />
						</IconButton>
					)}
					{loading ? <CircularProgress color="inherit" size={13} /> : <ExpandMore />}
				</Box>
			</Button>

			<Menu
				anchorEl={anchorEl} open={Boolean(anchorEl)}
				MenuListProps={{
					sx: { minWidth: 150, maxWidth: 375, maxHeight: 600 }
				}}
				onClose={handleClose}
			>
				{options.map((option, i) => {
					return (
						<MenuItem
							key={i}
							selected={selectedKeys.includes(option[dataKey])}
							sx={{ fontSize: '90%', whiteSpace: 'wrap' }}
							onClick={() => {
								handleSelect(option);
								if (!multiSelect) handleClose();
							}}
						>
							{multiSelect && <Checkbox checked={selectedKeys.includes(option[dataKey])} sx={{ p: 0, mr: 1 }} />}
							{option[dataText]}
						</MenuItem>
					);
				})}
			</Menu>
		</>
	);
}

export default TextDropdown;
