import { usePopupState } from "material-ui-popup-state/hooks";
import React, { useCallback } from "react";
import StartAuditDialog from "~/components/Dialogs/StartAuditDialog";

// context setup
const StartAuditDialogContext = React.createContext({
	popupState: {},
	open: (type = 'estimate_audit') => { }
});
export const __StartAuditDialogContext = StartAuditDialogContext;

export const StartAuditDialogProvider = ({ children }) => {
	const [type, setType] = React.useState('estimate_audit');

	const popupState = usePopupState({
		variant: 'dialog',
		popupId: 'StartAuditDialog',
	});

	const open = useCallback((type = 'estimate_audit') => {
		setType(type);
		popupState.open();
	}, [popupState]);

	return (
		<StartAuditDialogContext.Provider value={{ popupState, open, type }}>
			<>
				<StartAuditDialog
					isOpen={popupState.isOpen}
					onClose={popupState.close}
					type={type}
				/>
				{children}
			</>
		</StartAuditDialogContext.Provider>
	);
};
