import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import BuildIcon from '@mui/icons-material/Build';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import LockResetIcon from '@mui/icons-material/LockReset';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SaveIcon from '@mui/icons-material/Save';
import { Backdrop, Box, Chip, CircularProgress, Fab, SpeedDial, SpeedDialAction, Stack, Tooltip } from "@mui/material";
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '~/config';
import { handleDownload } from '~/helpers/windowHelper';
import useGlobalState from "~/hooks/useGlobalState";
import { useLayoutProvider } from "~/providers/LayoutProvider";
import { newFormSubmission } from "~/requests/submissions";
import { useForm } from "../../hooks/useForm";
import FixedFooter from "../FixedFooter";
import { Gate } from '../Gate';
import FormSubMenu from "./FormSubMenu";

const EstimateFormPage = ({ paddingBottom = 2, className = '', hasMenu = true, children }) => {
	const { submission, ...form } = useForm();
	const { sideDrawerOpen } = useLayoutProvider();
	const navigate = useNavigate();
	const { lastMainUrl } = useGlobalState();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const drawerWidth = hasMenu ? (isMobile ? 50 : 80) : 0;
	const hasErrors = Object.values(form.formik.errors).length > 0;

	const [creatingAudit, setCreatingAudit] = useState(false);
	const [open, setOpen] = useState(false);

	const handleStartAudit = useCallback(() => {
		setCreatingAudit(true);
		newFormSubmission('estimate_audit', { parent_uuid: submission.uuid })
			.then(({ data: { data: submission } }) => {
				enqueueSnackbar("Created new Audit", { variant: 'success' });
				form.invalidateQuery();
				navigate(`/forms/estimate_audit/${submission.uuid}`);
			})
			.catch((error) => {
				console.error(error);
				enqueueSnackbar("Error starting audit. Please try again", { variant: 'error' });
			})
			.finally(() => {
				setCreatingAudit(false);
			});
	}, [form, navigate, submission.uuid]);

	return (
		<>
			{hasMenu && (
				<FormSubMenu isMobile={isMobile} drawerWidth={drawerWidth} isOpen={sideDrawerOpen} />
			)}
			<Box
				component="main"
				style={{
					marginLeft: (sideDrawerOpen ? drawerWidth : 0),
				}}
				className={className}
				sx={{
					flexGrow: 1,
					p: 0,
					pb: paddingBottom,
					position: 'relative',
				}}>

				<Stack direction="row" gap={2} sx={{ mb: 2 }}>
					{form.isCompleted && (
						<Chip label="Submission Completed" color="success" sx={{
							fontSize: '1em'
						}} />
					)}

					{submission.is_synced && (
						<Chip label="Synced" color="info" sx={{
							fontSize: '1em'
						}} />
					)}
				</Stack>
				{children}
			</Box>

			{(submission.form.key === 'external_audit' && !form.isCompleted) && (
				<FixedFooter>
					<>
						<Tooltip title="Save Progress">
							<Fab
								color="primary"
								onClick={() => form.submitForm(false)}
								aria-label="Complete Form"
								disabled={form.isSubmitting}
							>
								{!form.isSubmitting && (
									<SaveIcon />
								)}

								{form.isSubmitting && (
									<CircularProgress color="white" sx={{
										padding: 1
									}} />
								)}
							</Fab>
						</Tooltip>
						<Tooltip title="Complete Form">
							<Fab
								color="primary"
								onClick={() => form.submitForm(true)}
								aria-label="Complete Form"
								disabled={form.isSubmitting}
							>
								{!form.isSubmitting && (
									<DoneOutlineIcon />
								)}

								{form.isSubmitting && (
									<CircularProgress color="white" sx={{
										padding: 1
									}} />
								)}
							</Fab>
						</Tooltip>
					</>
				</FixedFooter>
			)}

			{submission.form.key !== 'external_audit' && (

				<FixedFooter>
					<>
						{form.showPageNavigation && (
							<>
								{form.hasPreviousPage && (
									<Tooltip title="Previous Page">
										<Fab
											color="primary"
											aria-label="Previous Page"
											onClick={form.goPreviousPage}
										>
											<ArrowBackIosNewIcon />
										</Fab>
									</Tooltip>
								)}

								<Tooltip title="Next Page">
									<Fab
										color="primary"
										disabled={!form.hasNextPage}
										aria-label="Next Page"
										onClick={form.goNextPage}
									>
										<ArrowForwardIosIcon />
									</Fab>
								</Tooltip>
							</>
						)}

						{(form.isCompleted && submission.form.key == 'estimate') && (
							<Gate has="tenant.apps.audits.create">
								<Tooltip title="New Audit">
									<Fab
										color="secondary"
										onClick={() => handleStartAudit()}
										aria-label="New Audit"
										disabled={form.isSubmitting || creatingAudit}
									>
										{!creatingAudit && (
											<ContentPasteSearchIcon />
										)}

										{creatingAudit && (
											<CircularProgress color="white" sx={{
												padding: 1
											}} />
										)}

									</Fab>
								</Tooltip>
							</Gate>
						)}

						{(!form.isLastPage && !form.isCompleted) && (
							<Tooltip title="Save Progress">
								<Fab
									color={"primary"}
									onClick={() => form.submitForm(false)}
									aria-label="Save Progress"
									disabled={form.isSubmitting || hasErrors}
								>
									{!form.isSubmitting && (
										<SaveIcon />
									)}

									{form.isSubmitting && (
										<CircularProgress color="white" sx={{
											padding: 1
										}} />
									)}
								</Fab>
							</Tooltip>
						)}

						{(form.isLastPage && !form.isCompleted) && (
							<Tooltip title="Complete Form">
								<Fab
									color="primary"
									onClick={() => form.submitForm(true)}
									aria-label="Complete Form"
									disabled={form.isSubmitting}
								>
									{!form.isSubmitting && (
										<DoneOutlineIcon />
									)}

									{form.isSubmitting && (
										<CircularProgress color="white" sx={{
											padding: 1
										}} />
									)}
								</Fab>
							</Tooltip>
						)}

						{/* is admin */}
						<Gate has="tenant.apps.estimates.manage">
							<Backdrop open={open} className="radial-backdrop" />
							<SpeedDial
								icon={<SpeedDialIcon icon={<MoreVertIcon />} openIcon={<BuildIcon />} />}
								ariaLabel="Form Actions"
								transitionDuration={{ enter: 0, exit: 0 }}
								open={open}
								onClose={() => setOpen(false)}
								onOpen={() => setOpen(true)}

							>
								{(form.isCompleted) && (
									<SpeedDialAction
										icon={<FileDownloadIcon />}
										tooltipTitle="Download"
										tooltipOpen
										aria-label="Download Export"
										onClick={() => handleDownload(`${config.api.base_url}/export/${submission.uuid}`)}
									/>
								)}
								{form.isCompleted && (
									<SpeedDialAction
										icon={form.isSubmitting ? <CircularProgress color="white" sx={{
											padding: 1
										}} /> : <LockResetIcon />}
										tooltipTitle="Uncomplete"
										tooltipOpen
										aria-label="Uncomplete"
										onClick={() => form.uncomplete()}
									/>
								)}
								<SpeedDialAction
									icon={<DeleteIcon />}
									tooltipTitle="Delete"
									tooltipOpen
									aria-label="Delete Submission"
									onClick={() => form.deleteForm((data) => {
										// navigate back to parent form or last main url
										setTimeout(() =>
											data.parent?.form?.key === 'estimate'
												? navigate(`/forms/estimate/${data.parent.uuid}`)
												: navigate(lastMainUrl)
											, 500);
									})}
								/>
							</SpeedDial>
						</Gate>


					</>
				</FixedFooter>
			)}
		</>
	);
}

export default EstimateFormPage;