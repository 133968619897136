import { useCallback, useMemo, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Grid, Paper, Stack, ToggleButton, Typography } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import { Field } from 'formik';
import { TextField, ToggleButtonGroup } from 'formik-mui';
import { enqueueSnackbar } from 'notistack';
import FormInput from '~/components/Forms/FormInput';
import FormTitleBar from '~/components/Forms/FormTitleBar';
import { Gate } from '~/components/Gate';
import { mergeValuesWithInital, removeDuplicatesByKey } from '~/helpers/dataHelpers';
import { useGate } from '~/hooks/useGate';
import { calculatePanelCost } from '~/requests/submissions';
import EstimateFormPage from "../../components/Forms/EstimateFormPage";
import { addOtherPricedItem } from '../../helpers/dataHelpers';
import { capitalizeFirstLetter, toCurrency } from '../../helpers/stringHelpers';
import { useForm } from '../../hooks/useForm';
import { useFormEffect } from '../../hooks/useFormEffect';
import { baseMiscItems, baseRRItems, conventionalRepairTypes, panelsBase } from './data/data.js';
import PanelLabourModal from './panel-labour/PanelLabourModal';

const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	'&:not(:last-child)': {
		borderBottom: 0,
	},
	'&:before': {
		display: 'none',
	},
}));

const AccordionSummary = styled((props) => (
	<MuiAccordionSummary
		expandIcon={<ExpandMoreIcon />}
		{...props}
	/>
))(({ theme }) => ({
	borderBottom: '1px solid rgba(0, 0, 0, .125)',
	backgroundColor:
		theme.palette.mode === 'dark'
			? 'rgba(255, 255, 255, .05)'
			: 'rgba(0, 0, 0, .03)',
}));


const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderBottom: '1px solid rgba(0, 0, 0, .125)',
}));

const PageLabour = () => {
	const [modalOpen, setModalOpen] = useState(false);
	const [selectedPanel, setSelectedPanel] = useState(null);

	const { formik, getPanelPrice, submission, submitForm, getMiscPrice, estimateMeta, isCompleted, formKey } = useForm();
	const [useFixedValues, setUseFixedValues] = useState(false);

	const { abilities } = useGate();

	const panels = panelsBase(estimateMeta.bodyStyle).map((panel) => ({
		...panel,
		selected: formik.values.panels[panel.key] !== undefined,
		values: formik.values.panels[panel.key],
		costs: panel.costs || getPanelPrice(panel.key)
	}));


	const handleCloseModal = useCallback((e, reason) => {
		setModalOpen(false);
		setSelectedPanel(null);
	}, []);

	const handleOnDeleteModal = useCallback((panel) => {
		const panels = { ...formik.values.panels } || {};
		delete panels[panel.key];

		formik.setFieldValue('panels', panels);

		handleCloseModal(null, 'delete');
	}, [formik, handleCloseModal]);

	const handleOnSaveModal = useCallback(async (panel, values, removedParts = [], removedRr = [], onFinish = () => null) => {
		const panelsValue = formik.values.panels || {};
		const partsValue = formik.values.parts || [];
		const rrValue = formik.values['r&r'] || [];
		const blendsRR = values.blends.filter((blend) => blend.type === 'r&r');
		const blendsPanel = values.blends.filter((blend) => blend.type === 'panel');

		const updatedPanel = {
			...values,
			_panel: panel,
		};

		try {
			const panel_cost_query = await calculatePanelCost(submission.uuid, {
				panel_key: panel.key,
				panel: updatedPanel
			});
			const bodyStyle = estimateMeta.bodyStyle;

			let panel_cost = {
				pdr_total: 0,
				repair_total: 0,
				replacement_extras_total: 0,
				total: 0
			};

			if (panel_cost_query.data?.success) {
				panel_cost = panel_cost_query.data?.data;
			}

			// Check if this panel's blends have been selected as a primary panel and if so remove the blend selection from this panel only if the primary panel is a replace or has conventional work 
			if (blendsPanel.length) {
				blendsPanel.forEach((blend) => {
					const selectedPanel = panelsValue[blend.key];
					if (selectedPanel && (selectedPanel.replace_panel || selectedPanel.conventional_repair)) {
						updatedPanel.blends = updatedPanel.blends.filter((b) => b.key !== blend.key);
					}
				});
			}

			// check if any of the RR blends have a linkedPanel and that linkedPanel is in the list of panels
			if (blendsRR.length) {
				const selectedPanelKeys = Object.keys(panelsValue);

				blendsRR.forEach((blend) => {
					// if blend.linkedPanel in selectedPanelKeys, remove the blend from the list
					if (selectedPanelKeys.includes(blend.linkedPanel)) {
						updatedPanel.blends = updatedPanel.blends.filter((b) => b.key !== blend.key);
						removedRr.push(blend);
					};
				});
			}

			const mergedData = {
				...panelsValue,
				[panel.key]: {
					...updatedPanel,
					totals: panel_cost,
				}
			};
			const panelBaseKeys = panelsBase(estimateMeta.bodyStyle)?.map(panel => panel.key);
			const orderedData = panelBaseKeys.reduce((acc, key) => {
				if (key in mergedData) {
					acc[key] = mergedData[key];
				}
				return acc;
			}, {});

			// write new values to panels array 
			formik.setFieldValue('panels', orderedData);


			// Check if this panel has been selected in another panel blends only if self is a replace or conventional repair
			// also check if any of the other panels has any rr blends which are linked to this panel and remove them
			if ((updatedPanel.replace_panel || updatedPanel.conventional_repair)) {
				for (const [key, value] of Object.entries(panelsValue)) {
					if (key !== panel.key) {
						const toRemove = value.blends?.filter((blend) => blend.key === panel.key || blend.linkedPanel === panel.key) ?? [];
						const toRemoveKeys = toRemove.map((blend) => blend.key);
						removedRr.push(...toRemove.filter((blend) => blend.type === 'r&r')); // clean up rr items

						if (toRemoveKeys.length) {
							formik.setFieldValue(`panels.${key}`, {
								...value,
								blends: value.blends.filter((blend) => !toRemoveKeys.includes(blend.key))
							});
						}
					}
				}
			}


			if (removedParts.length) {
				formik.setFieldValue('parts', partsValue.filter((part) => !removedParts.some(removedPart => removedPart.key === part.key)));
			}
			if (values.parts.length) {
				formik.setFieldValue('parts', mergeValuesWithInital(values.parts, partsValue));
			}

			// const newRRItems = mergeValuesWithInital(updatedPanel.blends.filter((blend) => blend.type === 'r&r'), rrValue).filter((rr) => !removedRr.some(removedRr => removedRr.key === rr.key));
			const newRRItems = removeDuplicatesByKey(mergeValuesWithInital(updatedPanel.blends.filter((blend) => blend.type === 'r&r'), rrValue).filter((rr) => !removedRr.some(removedRr => removedRr.key === rr.key)));

			// update RR values from what is set 
			formik.setFieldValue('r&r', newRRItems);


			/* -------------- TRIGGERS BEGIN --------------- */
			if (values.replace_panel) {
				addOtherPricedItem('misc', 'seam_sealer', baseMiscItems, formik, getMiscPrice);
				addOtherPricedItem('misc', 'booth_allowance', baseMiscItems, formik, getMiscPrice);
				if (panel.key.startsWith('turret_')) {
					addOtherPricedItem('misc', 'glass_allowance', baseMiscItems, formik, getMiscPrice);
					addOtherPricedItem('misc', 'scan_reset', baseMiscItems, formik, getMiscPrice);
					addOtherPricedItem('r&r', 'new_turret_partial_trims', baseRRItems(bodyStyle), formik, getMiscPrice);
					addOtherPricedItem('r&r', 'front_screen', baseRRItems(bodyStyle), formik, getMiscPrice);
					addOtherPricedItem('r&r', 'aerial', baseRRItems(bodyStyle), formik, getMiscPrice);
					addOtherPricedItem('r&r', 'l_h_turret_mould', baseRRItems(bodyStyle), formik, getMiscPrice);
					addOtherPricedItem('r&r', 'r_h_turret_mould', baseRRItems(bodyStyle), formik, getMiscPrice);
					addOtherPricedItem('r&r', 'fittings_tgate', baseRRItems(bodyStyle), formik, getMiscPrice);
					addOtherPricedItem('r&r', 'tailgate_app_fittings', baseRRItems(bodyStyle), formik, getMiscPrice);

					if (['sedan', '2dr_ute', '4dr_ute'].includes(bodyStyle)) {
						addOtherPricedItem('r&r', 'rear_screen', baseRRItems(bodyStyle), formik, getMiscPrice);
					}


					const filteredRemoveRefit = formik.values['r&r'].reduce((acc, item) => {
						if (!['coupe', 'sedan', 'hatch_wagon', '4wd', 'ute', 'euro_complex'].includes(item.key)) {
							acc.push(item);
						}
						return acc;
					}, []);
					formik.setFieldValue('r&r', filteredRemoveRefit);
				}
			}
			else if (values.convention_repair) {
				addOtherPricedItem('misc', 'booth_allowance', baseMiscItems, formik, getMiscPrice);
			}
			/* -------------- TRIGGERS END --------------- */

			onFinish();
			handleCloseModal(null, 'save');
			// enqueueSnackbar("Panel updated");
			setTimeout(() => submitForm(false), 100);

		} catch (error) {
			console.log(error);
			onFinish();
			enqueueSnackbar("Failed to update panel", { variant: 'error' });
		}
	}, [formik, submission.uuid, estimateMeta.bodyStyle, handleCloseModal, getMiscPrice, submitForm]);

	const handlePanelClick = useCallback((panel) => {
		setSelectedPanel({ ...panel });
		setModalOpen(true);
	}, []);

	const selectedPanels = useMemo(() => {
		return panels.filter((panel) => panel.selected).length || 0;
	}, [panels]);

	const groupedPanels = useMemo(() => panels.reduce((groups, item) => {
		const groupName = item.group[0] || 'common';
		if (!groups[groupName]) {
			groups[groupName] = [];
		}
		groups[groupName].push(item);
		return groups;
	}, {}), [panels]);

	useFormEffect(["labour_quote_method"], (values) => {
		setUseFixedValues(values.labour_quote_method === "fixed");
	});

	return (
		<EstimateFormPage>
			<FormTitleBar title="Panel Labour" count={selectedPanels} />

			<Paper elevation={0} sx={{ padding: 0 }}>
				<Stack>

					<Box sx={{ paddingBottom: 1 }}>
						<Field
							name="labour_quote_method"
							component={FormInput}
							inputComponent={ToggleButtonGroup}
							type="checkbox"
							exclusive
							fullWidth
							color="primary"
						>
							<ToggleButton value="count">
								Count Method
							</ToggleButton>
							<ToggleButton value="fixed">
								Fixed Method
							</ToggleButton>
						</Field>
					</Box>

					<Box sx={{ paddingBottom: 2 }}>
						<Field
							name="prestige"
							component={FormInput}
							inputComponent={ToggleButtonGroup}
							type="checkbox"
							exclusive
							fullWidth
							color="primary"
						>
							<ToggleButton value={false} aria-label="Not Prestige" >
								Not Prestige
							</ToggleButton>
							<ToggleButton value={true} aria-label="Prestige" >
								Prestige
							</ToggleButton>
						</Field>
					</Box>

				</Stack>
			</Paper>

			<Stack>
				<Typography gutterBottom variant="h2" >Panels:</Typography>

				{Object.entries(groupedPanels).map(([groupName, panels]) => (

					<Accordion
						TransitionProps={{ unmountOnExit: true }}
						defaultExpanded={groupName === "common"}
						key={groupName}
					>
						<AccordionSummary>
							<Typography>{capitalizeFirstLetter(groupName)}</Typography>
						</AccordionSummary>
						<AccordionDetails sx={{ padding: 1 }}>
							{panels.map((panel, index) => (
								<Box paddingBottom={1} key={index}>
									<Button
										sx={{
											borderWidth: 2,
											borderColor: panel.selected ? "primary.main" : "lightgrey",
											borderRadius: 2,
											borderStyle: "solid",
											textTransform: "none",
											color: "black",
											padding: 1,
											textAlign: "left",
										}}
										onClick={() => handlePanelClick(panel)}
										fullWidth
										disabled={isCompleted && !panel.selected}
									>
										<Box style={{ width: "100%" }}>

											<Grid container spacing={1}>
												<Grid item xs={9}>
													<Stack direction="row" spacing={1}>
														<Typography color="black" sx={{ fontWeight: panel.selected ? 600 : null }}>
															{panel.name}
														</Typography>
														{panel.selected && (
															<>
																{panel.values.report && (
																	<Typography title="Report">{'(R)'}</Typography>
																)}
																{panel.values.pdr_alloy && (
																	<Typography title="Alloy">{'(A)'}</Typography>
																)}
															</>
														)}
													</Stack>
												</Grid>
												<Grid item xs={3} textAlign="right">
													<Gate has={(formKey === 'estimate' && abilities.tenant.apps.estimates.see_financials) || (formKey === 'estimate_audit' && abilities.tenant.apps.audits.see_financials)}>

														{(panel.selected) && (
															<Typography sx={{ fontWeight: 800 }}>${toCurrency(panel.values?.totals?.total, 0, '-')}</Typography>
														)}
													</Gate>
												</Grid>
											</Grid>

											{panel.selected && (
												<>
													{!panel.values.replace_panel && (
														<>
															{((panel.values.conventional_repair != conventionalRepairTypes.full.value) && panel.values.pdr_dent_count) && (
																<Grid
																	container
																	spacing={1}
																>
																	<Grid item xs={8}>
																		<Stack direction="row" spacing={1} >
																			<Typography>
																				PDR
																			</Typography>
																			{panel.values.pdr_manual_price && (
																				<Typography title="Manual Price">{'(M)'}</Typography>
																			)}
																		</Stack>
																	</Grid>
																	<Grid item xs={4} textAlign="right">
																		{panel.values.pdr_dent_count && (
																			<Typography>
																				{panel.values.pdr_dent_count} / {(panel.values.pdr_dent_size + '').capitalize()}
																			</Typography>
																		)}
																	</Grid>
																</Grid>
															)}

															{panel.values.pdr_class && (

																<Grid container spacing={1}>
																	<Grid item xs={4}>
																		<Typography>
																			PDR
																		</Typography>
																	</Grid>
																	<Grid item xs={8} textAlign="right">
																		<Typography>
																			{panel.values.pdr_class.capitalize()}
																		</Typography>
																	</Grid>
																</Grid>
															)}

															{panel.values.conventional_repair && (
																<Grid
																	container
																	spacing={1}
																>
																	<Grid item xs={4} md={6}>
																		<Typography>
																			{conventionalRepairTypes[panel.values.conventional_repair].label}
																		</Typography>
																	</Grid>
																	<Grid item xs={8} md={6} textAlign="right">
																		<Typography>
																			{[
																				(panel.values.conventional_repair + '').capitalize(),
																				...(panel.values.conventional_repair_extras['r&r'] ? ['R&R'] : []),
																				...(panel.values.conventional_repair_extras.paint ? ['Paint'] : []),
																			].join(', ')}
																		</Typography>
																	</Grid>
																</Grid>
															)}
														</>
													)}

													{panel.values.replace_panel &&
														<Grid
															container
															spacing={1}
														>
															<Grid item xs={4}>
																<Typography>
																	Replace
																</Typography>
															</Grid>
															<Grid item xs={8}>
																<Grid container style={{ gap: 5 }}>
																	<Typography>
																		{[
																			(panel.values.replace_panel_extras['r&r'] ? 'R&R' : null),
																			(panel.values.replace_panel_extras.paint ? 'Paint' : null),
																			(panel.values.replace_panel_extras.paint_blend ? 'Paint Blend' : null),
																		].join(', ')}
																	</Typography>
																</Grid>
															</Grid>
														</Grid>
													}
												</>
											)}
										</Box>
									</Button>
								</Box>
							))}


						</AccordionDetails>
					</Accordion>
				))
				}

				<Field
					component={TextField}
					name="panel_notes"
					label="Labour Notes"
					fullWidth
					InputProps={{
						multiline: true,
						minRows: 5,
						maxRows: 10
					}}
					sx={{
						mt: 2
					}}
				/>
			</Stack>

			{/* Modal */}
			{
				modalOpen && (
					<PanelLabourModal
						panel={selectedPanel}
						parentFormikValues={formik.values}
						isOpen={modalOpen}
						onSave={handleOnSaveModal}
						onDelete={handleOnDeleteModal}
						onClose={handleCloseModal}
						useFixedValues={useFixedValues}
					/>
				)
			}

			<FormEffects />
		</EstimateFormPage>
	);
}

export default PageLabour;


const FormEffects = () => {
	useFormEffect(['panels'], (values, formik) => {

		const parts = formik.values.parts || [];

		for (const [panel_key, panel] of Object.entries(values.panels)) {
			if (panel.replace_panel) {
				if (!parts.some((part) => part.key === panel_key)) {
					parts.push({
						key: panel_key,
						label: panel._panel.name,
					});

					formik.setFieldValue('parts', parts);
				}
			}
		}
	});

	return null;
}
