import AccountTreeIcon from '@mui/icons-material/AccountTree';
import BarChartIcon from '@mui/icons-material/BarChart';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DomainIcon from '@mui/icons-material/Domain';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import MapIcon from '@mui/icons-material/Map';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import PaletteIcon from '@mui/icons-material/Palette';
import PeopleIcon from '@mui/icons-material/People';
import StoreIcon from '@mui/icons-material/Store';

const menuItems = [
	{
		id: 'dashboard',
		children: [
			{
				title: 'Dashboard',
				type: 'item',
				url: '/',
				icon: DashboardIcon,
				gate: 'tenant.apps.dashboard.view'
			}
		]
	},
	{
		id: 'apps',
		title: 'Apps',
		children: [
			{
				title: 'Estimates',
				type: 'item',
				url: '/estimates',
				icon: DynamicFormIcon,
				gate: 'tenant.apps.estimates.view'
			},
			{
				title: 'Audits',
				type: 'collapse',
				icon: ContentPasteSearchIcon,
				children: [
					{
						title: 'Internal',
						type: 'item',
						url: '/audits',
						gate: 'tenant.apps.audits.view'
					},
					{
						title: 'External',
						type: 'item',
						url: '/external-audits',
						gate: 'tenant.apps.external-audits.view'
					},
				]
			},
			{
				title: 'Assessments',
				type: 'collapse',
				icon: CalendarMonthIcon,
				children: [
					{
						title: 'Calendar',
						type: 'item',
						url: '/assessments/calendar',
						disabled: true,
						gate: 'tenant.apps.assessments.view',
					},
					{
						title: 'Triage Queue',
						type: 'item',
						url: '/assessments/triage',
						disabled: true,
						gate: 'tenant.apps.assessments.triage.view',
					},
				]
			},
			{
				title: 'Bookings',
				type: 'item',
				url: '/bookings',
				icon: CalendarMonthIcon,
				gate: 'tenant.apps.bookings.view',
			},
			{
				title: 'Heatmap',
				type: 'item',
				url: '/heatmap',
				icon: MapIcon,
				disabled: true,
				gate: 'tenant.apps.heatmap.view',
			}
		]
	},
	{
		id: 'manage',
		title: 'Manage',
		children: [
			{
				title: 'Debtors',
				type: 'item',
				url: '/manage/debtors',
				icon: MoneyOffIcon,
				gate: 'tenant.manage.debtors.view',
			},
			{
				title: 'Departments',
				type: 'item',
				url: '/manage/departments',
				icon: AccountTreeIcon,
				gate: 'tenant.manage.departments.view',
			},
			{
				title: 'Events',
				type: 'item',
				url: '/manage/events',
				icon: MapIcon,
				gate: 'tenant.manage.events.view',
			},
			{
				title: 'Facilities',
				type: 'item',
				url: '/manage/facilities',
				icon: StoreIcon,
				gate: 'tenant.manage.facilities.view',
			},
			{
				title: 'Paint Codes',
				type: 'item',
				url: '/manage/paint-codes',
				icon: PaletteIcon,
				gate: 'tenant.manage.paint_codes.view',
			},
			{
				title: 'Users',
				type: 'item',
				url: '/manage/users',
				icon: PeopleIcon,
				gate: 'tenant.manage.users.view',
			},
			{
				title: 'Workproviders',
				type: 'item',
				url: '/manage/workproviders',
				icon: DomainIcon,
				gate: 'tenant.manage.work_providers.view',
			},
		]
	},
	{
		id: 'reports',
		title: 'Reports',
		gate: 'tenant.reports.*',
		children: [
			{
				title: 'Quotes',
				type: 'collapse',
				icon: BarChartIcon,
				children: [
					{
						title: 'Submissions',
						type: 'item',
						url: '/reports/quotes/submissions',
						disabled: true,
					},
					{
						title: 'Failed Export',
						type: 'item',
						url: '/reports/quotes/failed-export',
						disabled: true,
					}
				]
			},
			{
				title: 'Tables',
				type: 'collapse',
				icon: BarChartIcon,
				children: [
					{
						title: 'Assessments',
						type: 'item',
						url: '/reports/jobs/assessments',
						disabled: true,
					},
					{
						title: 'Bookings',
						type: 'item',
						url: '/reports/jobs/bookings',
						disabled: true,
					}
				]
			},
			{
				title: 'Audits',
				type: 'item',
				icon: BarChartIcon,
				disabled: true
			}
		]
	}
	// {
	// 	id: 'settings',
	// 	title: 'Settings',
	// 	children: [
	// 		{
	// 			title: 'Manage Users',
	// 			type: 'collapse',
	// 			icon: SearchIcon,

	// 			children: [
	// 				{
	// 					id: 'login3',
	// 					title: 'Login',
	// 					type: 'item',
	// 					url: '/pages/login/login3',
	// 					target: true
	// 				},
	// 				{
	// 					id: 'register3',
	// 					title: 'Register',
	// 					type: 'item',
	// 					url: '/pages/register/register3',
	// 					target: true
	// 				}
	// 			]
	// 		}
	// 	]
	// }
];

export default menuItems;
